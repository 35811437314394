import { ReactElement } from 'react';
import Image from 'components/Image/Image';
import PageWidth from 'styles/components/PageWidth';
import { Title } from 'styles/components/Title';
import data from './data';

const AsFeaturedOn = (): ReactElement => (
  <PageWidth>
    <Title className="text-center">As Featured On</Title>

    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {data.map(({ title, src, href }) => (
        <a
          className="text-main cursor-pointer flex flex-col items-center mb-6 hover:scale-up"
          href={href}
          key={title}
          target="__blank"
        >
          {src && (
            <div className="relative h-36 md:h-48 w-36 md:w-full mb-2 text-center">
              <Image src={src} alt={`${title} Logo`} height={192} width={192} />
            </div>
          )}
          {title}
        </a>
      ))}
    </div>
  </PageWidth>
);

export default AsFeaturedOn;
